@use '../../variables.scss';

section.projects {
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-top: 20px;
  border-bottom: 1px solid variables.$color-primary;
  padding-bottom: 20px;

  > .head {
    > h1 {
      font-size: 30px;
      font-weight: bold;
    }
  }

  > .list {
    margin-top: 20px;
    padding-left: 20px;

    > .project {
      &:not(:last-child) {
        padding-bottom: 20px;
        margin-bottom: 20px;
        border-bottom: 1px solid transparentize($color: variables.$color-primary, $amount: 0.8);
      }

      > .name {
        display: flex;
        flex-direction: row;
        align-items: flex-end;
        margin-bottom: 2px;

        > h2 {
          font-size: 26px;
          font-weight: bold;
          margin-right: 10px;
        }
      }

      > .url {
        font-size: 12px;
        color: variables.$color-primary;
        margin-bottom: 10px;

        > a {
          font-weight: 600;
        }
      }

      > .description {
        > p {
          font-size: 20px;
        }
      }
    }
  }
}
