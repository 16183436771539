@use '../../variables.scss';

@media (min-width: 1025px) {
  section.skills {
    display: flex;
    flex-direction: column;
    width: 100%;
    margin-top: 20px;
    border-bottom: 1px solid variables.$color-primary;
    padding-bottom: 20px;

    > .head {
      > h1 {
        font-size: 30px;
        font-weight: bold;
      }
    }

    > .list {
      display: flex;
      flex-direction: row;
      justify-content: space-around;
      margin-top: 20px;

      > .chunk {
        > table {
          flex-grow: 1;

          > tbody {
            > tr {
              > td {
                padding: 2px 0;
              }

              > td.name {
                padding: 0 10px 0 5px;
                font-size: 18px;
                font-weight: bold;
              }

              > td.years {
                font-size: 12px;
                font-weight: bold;

                > svg {
                  margin-right: 3px;
                }
              }
            }
          }
        }
      }
    }

    > .foot {
      display: flex;
      flex-direction: row;
      justify-content: space-around;
      padding-top: 20px;
      font-size: 12px;
      font-weight: bold;

      > span {
        > svg {
          margin-right: 3px;
        }
      }
    }
  }
}

@media (max-width: 1024px) {
  section.skills {
    display: flex;
    flex-direction: column;
    width: 100%;
    margin-top: 20px;
    border-bottom: 1px solid variables.$color-primary;
    padding-bottom: 20px;

    > .head {
      > h1 {
        font-size: 30px;
        font-weight: bold;
      }
    }

    > .list {
      display: flex;
      flex-direction: column;
      justify-content: space-around;
      margin-top: 20px;
      padding-left: 20px;

      > .chunk {
        > table {
          flex-grow: 1;
          width: 100%;

          > tbody {
            > tr {
              > td {
                padding: 2px 0;
              }

              > td.icon {
                width: 30px;
              }

              > td.name {
                padding: 0 10px 0 5px;
                font-size: 18px;
                font-weight: bold;
                width: 170px;
              }

              > td.years {
                font-size: 12px;
                font-weight: bold;

                > svg {
                  margin-right: 3px;
                }
              }
            }
          }
        }
      }
    }

    > .foot {
      display: flex;
      flex-direction: row;
      justify-content: space-around;
      padding-top: 20px;
      font-size: 12px;
      font-weight: bold;
      padding-left: 10px;
      padding-right: 10px;

      > span {
        > svg {
          margin-right: 3px;
        }
      }
    }
  }
}
