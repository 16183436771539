// Colors
$color-primary: #3d3d3d;
$color-text: #252525;

$color-advanced: #3a3a3a;
$color-proficient: #ad0f2c;
$color-intermediate: #ffff00;
$color-entry: #00ff00;

// Borders
$profile-picture-border-width: 6px;
