@use '../../variables.scss';

@media (min-width: 1025px) {
  section.header {
    display: flex;
    flex-direction: row;
    padding: 20px 0 10px 0;

    > .profile-picture {
      display: flex;
      align-items: center;

      > img {
        width: 150px;
        height: 150px;
        border-radius: 100%;
        border-color: variables.$color-primary;
        border-width: variables.$profile-picture-border-width;
        border-style: solid;
      }
    }

    > .info {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: flex-end;
      flex-grow: 2;
      margin-left: 20px;

      > .name {
        text-align: right;

        > h1 {
          font-size: 40px;
          font-weight: bold;
        }
      }

      > .contact {
        display: flex;
        flex-direction: column;
        align-items: flex-end;
        margin-top: 10px;

        > a {
          font-weight: bold;
          margin-top: 10px;
          display: flex;
          flex-direction: row;
          justify-content: center;
          align-items: center;

          > svg {
            margin-right: 10px;
          }
        }
      }

      > .social {
        border-top-color: variables.$color-primary;
        border-top-width: 1px;
        border-top-style: solid;
        width: 100%;
        margin-top: 10px;
        padding-top: 10px;
        display: flex;
        flex-direction: row;
        justify-content: space-around;

        > a {
          font-weight: bold;
          display: flex;
          flex-direction: row;
          justify-content: center;
          align-items: center;

          > svg {
            margin-right: 5px;
          }
        }
      }
    }
  }
}


@media (max-width: 1024px) {
  section.header {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 20px 0 10px 0;

    > .profile-picture {
      display: flex;
      align-items: center;

      > img {
        width: 150px;
        height: 150px;
        border-radius: 100%;
        border-color: variables.$color-primary;
        border-width: variables.$profile-picture-border-width;
        border-style: solid;
      }
    }

    > .info {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      flex-grow: 2;
      margin-left: 20px;

      > .name {
        text-align: center;

        > h1 {
          font-size: 40px;
          font-weight: bold;
        }
      }

      > .contact {
        display: flex;
        flex-direction: column;
        align-items: center;
        margin-top: 10px;

        > a {
          font-weight: bold;
          margin-top: 10px;
          display: flex;
          flex-direction: row;
          justify-content: center;
          align-items: center;

          > svg {
            margin-right: 10px;
          }
        }
      }

      > .social {
        border-top-color: variables.$color-primary;
        border-top-width: 1px;
        border-top-style: solid;
        width: 100%;
        margin-top: 10px;
        padding-top: 10px;
        display: flex;
        flex-direction: column;
        justify-content: space-around;

        > a {
          font-weight: bold;
          display: flex;
          flex-direction: row;
          justify-content: center;
          align-items: center;
          margin-bottom: 5px;

          > svg {
            margin-right: 5px;
          }
        }
      }
    }
  }
}
